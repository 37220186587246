<template>
  <section
    id="product"
    class="mb-n5"
  >
    <core-jumbotron-mobile />

    <v-alert
      v-if="show_promotion"
      text
      prominent
      dense
      outlined
      shaped
      color="success"
      icon="mdi-tag"
      class="ma-4"
    >
      <h3 class="headline">
        {{ promotion.data().content[$i18n.locale].title }}
      </h3>
      <small v-if="user">
        <v-icon
          aria-hidden="false"
          color="blue"
          x-small
        >
          mdi-lock
        </v-icon> {{ moment(promotion.data().from,'X' ).format('MMMM Do YYYY') }} - {{ moment(promotion.data().to,'X' ).format('MMMM Do YYYY') }}
      </small>
      <v-row
        align="center"
        no-gutters
      >
        <v-col class="grow">
          {{ promotion.data().content[$i18n.locale].sub_title }}
        </v-col>
      </v-row>

      <v-row

        no-gutters
        align="end"
        align-content="end"
      >
        <v-col
          cols="12"
          align-self="end"
        >
          <v-btn
            v-if="user"
            right
            outlined
            class="ma-1 float-right"
            @click="updatePromotion"
          >
            Edit
          </v-btn>
          <v-btn
            right
            class="ma-1 float-right"
            outlined
            :to="'/' + $i18n.locale + '/contact?product='+this.$route.params.company+','+this.$route.params.product"
          >
            {{ $t("product.enquire") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <promote-product
      v-if="user"
      ref="promoteProductComponent"
    />
    <v-row
      v-if="user && !show_promotion"
      align="center"
      no-gutters
    >
      <v-col class="grow text-center">
        <v-btn
          dark
          class="align-content-center"
          v-on="on"
          @click="createPromotion"
        >
          Create promotion
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="white hidden-sm-and-down">
      <v-col offset="1">
        <v-btn
          v-if="false && product.sku"
          class=""
          x-large
          right
          color="success"
          dark
          @click="redirectToCheckout"
        >
          <v-icon>mdi-credit-card-outline</v-icon>  Purchase Online
        </v-btn>
      </v-col>
      <v-col offset="6">
        <router-link
          v-if="company"
          :to="{ name: 'company', params: { lang:$i18n.locale,company: company.id }}"
        >
          <v-img

            class="d-md-flex "
            max-width="100"
            :src="company.logo"
          />
        </router-link>
      </v-col>
    </v-row>

    <v-container>
      <v-row
        align="center"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-card outlined>
            <v-img :src="product.image" />
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <v-card elevation="0">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1 text-wrap ">
                  {{ product.name }}
                </v-list-item-title>
                <p
                  v-if="product.price"
                  class="subtitle-1"
                >
                  <strong>Price</strong>: €{{ product.price }}
                </p>
                <v-list-item-subtitle v-if="product.subtitle">
                  {{ product.subtitle }}
                </v-list-item-subtitle>
                <p
                  class="body-1"
                  :style="product.preformatted?'white-space: pre-wrap;':''"
                  v-html="product.description"
                />

                <v-btn
                  v-if="false && product.sku"
                  class="mt-10 hidden-md-and-up"
                  x-large
                  color="success"
                  dark
                  @click="redirectToCheckout"
                >
                  <v-icon>mdi-credit-card-outline</v-icon>  Purchase Online
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        v-for="(list, i) in product.lists"
        :key="i"
      >
        <v-col
          cols="12"
        >
          <v-list light>
            <p
              v-if="list.name"
              class="title ml-4"
            >
              {{ list.name }}
            </p>
            <p
              v-if="list.description"
              class="body-1 ml-5"
              :style="product.preformatted?'white-space: pre-wrap;':''"
              v-html="list.description"
            />

            <v-list-item
              v-for="(item, i) in list.items"
              :key="i"
              class="mt-5"
              dense
            >
              <v-list-item-icon v-if="!item.noicon ">
                <v-icon
                  v-if="list.icon"
                  v-text="list.icon"
                />
                <v-icon v-if="!list.icon">
                  mdi-square-medium-outline
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <p
                  v-if="item.title"
                  class=" font-weight-bold mb-0"
                >
                  {{ item.title }}
                </p>

                <p
                  v-if="item.text"
                  class="body-1 "
                  v-html="item.text"
                />
                <v-list
                  v-if="item.innerItems"
                  light
                >
                  <v-list-item
                    v-for="(innerItem, ii) in item.innerItems"
                    :key="ii"
                    dense
                  >
                    <v-list-item-icon v-if="!innerItem.noicon ">
                      <v-icon>mdi-square-medium-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <p
                        v-if="innerItem.title"
                        class=" font-weight-bold mb-0"
                      >
                        {{ innerItem.title }}
                      </p>
                      {{ innerItem.text }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-row
                  v-if="item.images"
                  class=" text-center fill-height"
                >
                  <v-col
                    v-for="(image, i) in item.images"
                    :key="i"
                    sm="12"
                    md="3"
                    cols="12"
                  >
                    <v-img
                      v-if="image"
                      class=" elevation-5"
                      :src="image"
                    />
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <v-row

        class="mb-5"
      >
        <v-col>
          <v-card
            v-if="product.brochures"
            flat
            tile
          >
            <v-card-title class="title">
              {{ $t("product.brochure_downloads") }}
            </v-card-title>

            <v-card-text>
              <v-btn
                v-for="(brochure, i) in product.brochures"
                :key="i"
                target="_blank"
                :href="brochure.url"
                class="ma-2"
                rounded
                outlined
                color="primary"
              >
                <v-icon>mdi-file-pdf-outline</v-icon> {{ brochure.name }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row

        class="mb-5"
      >
        <v-col>
          <v-card
            v-if="product.images"
            flat
            tile
          >
            <v-card-text>
              <carousel3d
                :display="product.images.length<3?1:3"
                :controls-visible="product.images.length>1"
                :autoplay="!product.images[0].video"
                :autoplay-timeout="4000"
                :autoplay-hover-pause="true"
                :width="600"
                :height="400"
                :perspective="product.images.length==2?0:40"
              >
                <slide
                  v-for="(slide,i) in product.images"
                  :key="i"
                  :index="i"
                >
                  <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
                    <iframe
                      v-if="slide.video"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                      width="600"
                      height="400"
                      type="text/html"
                      :src="slide.video"
                    />
                    <img
                      v-if="!slide.video"
                      :data-index="index"
                      :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }"
                      :src="slide"
                    >
                  </template>
                </slide>
              </carousel3d>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-overlay
        :value="overlay"
        z-index="10"
        class="text-center"
      >
        <v-btn
          class="ma-2 "
          fab
          dark
          small
          color="accent"

          @click="false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="text-center ">
          <v-img
            :src="overlay"
            class="elevation-5"
            :width="windowWidth*.75"
            @click="overlay = false"
          />
        </div>
      </v-overlay>
    </v-container>
  </section>
</template>

<script>
  import moment from 'moment'
  import i18n from '../i18n'
  import { Stripe } from 'stripe'
  import { Carousel3d, Slide } from 'vue-carousel-3d'
  export default {

    metaInfo () {
      return {
        title: this.title,

      }
    },
    components: {
      Carousel3d,
      Slide,
      CoreJumbotronMobile: () => import('@/components/core/JumbotronMobile'),
      PromoteProduct: () => import('@/components/account/PromoteProduct'),
    },

    data: () => ({
      user: null,
      future_promotion: false,
      title: null,
      overlay: false,
      loading: false,
      company: null,
      product: null,
      promotion: {},
      show_promotion: false,
      error: null,
      windowWidth: window.innerWidth,

    }),
    watch: {
      // call again the method if the route changes
      $route: 'fetchData',

    },

    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      this.fetchData()
      const vm = this
      this.$firebaseAuth.onAuthStateChanged(function (user) {
        if (user) {
          vm.user = user
        } else {
          vm.user = null
        }
      })
    },

    mounted () {
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          this.windowWidth = window.innerWidth
        })
      })
    },

    methods: {
      redirectToCategory () {
        this.$router.push({ path: 'products', query: { category: this.product.category } })
      },
      moment,
      createPromotion () {
        this.$refs.promoteProductComponent.updatePromotion(this.$route.params.company + '/' + this.$route.params.product)
      },
      updatePromotion () {
        this.$refs.promoteProductComponent.updatePromotion(this.$route.params.company + '/' + this.$route.params.product, this.promotion)
      },
      redirectToCheckout () {
        const stripe = Stripe('pk_test_NIxpxVaULyquERcEQEaudiqG00ZbBrAczX')
        stripe.redirectToCheckout({
          items: [
            // Replace with the ID of your SKU
            { sku: this.product.sku, quantity: 1 },
          ],
          successUrl: location.protocol + '//' + location.host + location.pathname + '?payment=success',
          cancelUrl: location.protocol + '//' + location.host + location.pathname + '?payment=cancel',
        }).then(function (result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        })
      },

      fetchData () {
        this.error = this.post = null
        this.loading = true
        // fetch company info
        fetch('/api/' + i18n.locale + '/company/' + this.$route.params.company + '.json')
          .then(r => r.json())
          .then(json => {
                  this.loading = false

                  this.company = json
                },
                response => {
                  console.log('Error loading json:', response)
                })
        // fetch product info
        fetch('/api/' + i18n.locale + '/product/' + this.$route.params.company + '/' + this.$route.params.product + '.json')
          .then(r => r.json())
          .then(json => {
                  this.loading = false

                  this.product = json
                  this.title = json.name
                },
                response => {
                  console.log('Error loading json:', response)
                })
        this.promotion = null
        const promotionsRef = this.$firebaseDatabase.collection('promotions')
        const _this = this

        promotionsRef.where('to', '>', moment().unix()).where('product', '==', this.$route.params.company + '/' + this.$route.params.product).orderBy('to', 'desc').limit(1).get().then(function (querySnapshot) {
          if (querySnapshot.size === 1) {
            _this.promotion = querySnapshot.docs[0]

            if (_this.promotion.data().from < moment().unix() || _this.user) {
              _this.show_promotion = true
              _this.future_promotion = _this.promotion.data().from > moment().unix()
            }
          }
        })
      },

    },

  }
</script>
<style>
  .v-list-item--dense {
    min-height: 20px !important;

  }
.v-list-item__content{
  padding:0 !important;
}
    .v-list-item__icon{
      margin-top:0 !important;
      margin-bottom:0 !important;
  }
</style>
<style lang="sass">
  @import '~vuetify/src/styles/styles.sass'
  #projects
    .v-slide-group__content
      justify-content: center
</style>
